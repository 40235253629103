import { currencies } from '../../shared'

export const colors = {
  pageviews: '#FF8656',
  clicks: '#FFB000',
  conversions: '#6A98FD',
  commission: '#0CCFAF',
}

export const dateDiffUnits = {
  hour: 'hours',
  date: 'days',
  week: 'weeks',
  month: 'months',
  year: 'years',
}

export const dateDiffFormats = {
  hour: 'YYYY-MM-DD HH',
  date: 'YYYY-MM-DD',
  week: 'YYYY-ww',
  month: 'YYYY-MM',
  year: 'YYYY',
}

export const graphHeight = 350

export const defaultGroupBy = 'name'
export const availableGroupBy = [
  'name', // advertiser
  'channel',
  'page',
  'device',
  'target',
  'network',
  'referer',
  'connection',
  'tracker',
  'shortcode',
  'status',
  'currency',
  'date',
  'week',
  'month',
  'year',

  'revshare',

  'advertiser',
]

export const groupLabels = {
  'date': 'Date',
  'name': 'Merchant',
  'tracker': 'Campaign',
  'channel': 'Site',
  'page': 'Page',
  'device': 'Device',
  'target': 'Target',
  'network': 'Network',
  'connection': 'Network account',
  'currency': 'Currency',
  'shortcode': 'Smart link',
  'referer': 'Referrer',

  'status': 'Status',
  'week': 'Week',
  'month': 'Month',
  'year': 'Year',

  'revshare': 'Boost',
}

export const filterLabels = {
  'date': 'Date',
  'month': 'Month',
  'year': 'Year',
  'statusKeys': 'Conversion status',
  'currencyCodes': 'Currency',
  'deviceTypes': 'Device',
  'advertiserUuids': 'Merchant',
  'channelUuids': 'Site',
  'networkUuids': 'Network',

  'pageUrlPaths': 'Page',
  'targetUrlPaths': 'Target',
  'referers': 'Referrer',
  'connectionUuids': 'Connection',
  'trackerUuids': 'Campaign',
  'urlShortCodes': 'Smart link',

  'names': 'Merchant',

  'subIds': 'Sub ID',
  'subId1s': 'Sub ID 1',
  'subId2s': 'Sub ID 2',
  'subId3s': 'Sub ID 3',
  'subId4s': 'Sub ID 4',
  'subId5s': 'Sub ID 5',

  'tags': 'Marketing tag',

  'revshareAccountUuids': 'Boost',
  'assigneeUserUuids': 'Managers',
  'advertiserStatuses': 'Status',
}

export const filterOptions = {
  'statusKeys': {
    'approved': 'Approved',
    'pending': 'Pending',
    'rejected': 'Rejected',
    'paid': 'Paid',
  },
  'currencyCodes': currencies.reduce((result, currency) => {
    result[currency] = currency
    return result
  }, {}),
  'deviceTypes': {
    'desktop': 'Desktop',
    'mobile': 'Mobile',
  },
  'advertiserStatuses': {
    '': 'No status',
    // 'stage:todo': 'To do',
    // 'stage:inprogress': 'In progress',
    // 'stage:notnow': 'Not now',
    // 'stage:no': 'No',
    // 'stage:complete': 'Completed',
    'partner:premium': 'Partner level: Premium',
    'partner:affiliate': 'Partner level: Affiliate',
    'partner:enterprise': 'Partner level: Enterprise',
    'deal:mrr': 'Deal type: MRR',
    'deal:enterprise': 'Deal type: Enterprise',
    'deal:advertisement': 'Deal type: Advertisement',
  },
}

export const groupFilterKeys = {
  'name': 'names',
  'channel': 'channelUuids',
  'page': 'pageUrlPaths',
  'device': 'deviceTypes',
  'target': 'targetUrlPaths',
  'network': 'networkUuids',
  'referer': 'referers',

  'connection': 'connectionUuids',
  'currency': 'currencyCodes',
  'status': 'statusKeys',
  'tracker': 'trackerUuids',
  'shortcode': 'urlShortCodes',

  'revshare': 'revshareAccountUuids',
}

export const tableColumnsKey = 'insights-statistics-columns'
export const defaultTableColumns = [
  'name',
  'pageviewCount',
  'clickCount',
  'ctr',
  'conversionCount',
  'cr',
  'rejectedCount',
  'rr',
  'conversionSale',
  'conversionCommission',
  'conversionClickCommission',
  'conversionTotalCommission',
  'conversionPendingCommission',
  'conversionRejectedCommission',
  'grossSale',
  'grossConversionCount',
  'networks',
  'trackers',
  'assignees',
  'advertiserStatus',
  'ecr',
  'epc',
  'epm',
]

export const hideMerchantColumns = ['pageviewCount', 'ctr', 'epm']
export const hidePageviewColumns = [
  'pageviewCount',
  'ctr',
  'epm',
  'assignees',
  'advertiserStatus',
]
export const hideChannelColumns = ['assignees', 'advertiserStatus']
export const hideNetworkColumns = ['networks', 'assignees', 'advertiserStatus']
export const hideTrackerColumns = ['trackers', 'assignees', 'advertiserStatus']
export const defaultHideColumns = [
  'conversionCommission',
  'conversionClickCommission',
  'assignees',
  'advertiserStatus',
]

export const columnsDesc = [
  'pageviewCount',
  'clickCount',
  'ctr',
  'conversionCount',
  'cr',
  'rejectedCount',
  'rr',
  'conversionSale',
  'conversionCommission',
  'conversionClickCommission',
  'conversionTotalCommission',
  'conversionPendingCommission',
  'conversionRejectedCommission',
  'grossSale',
  'grossConversionCount',
  'ecr',
  'epc',
  'epm',
]

export const tableLabels = {
  'name': '',
  'pageviewCount': 'Pageviews',
  'clickCount': 'Clicks',
  'ctr': 'CTR',
  'conversionCount': 'Conversions',
  'cr': 'CR',
  'rejectedCount': 'Rejections',
  'rr': 'Rejection rate',
  'conversionSale': 'Sale',
  'conversionCommission': 'Conv. commission',
  'conversionClickCommission': 'Click commission',
  'conversionTotalCommission': 'Total commission',
  'conversionPendingCommission': 'Pending commission',
  'conversionRejectedCommission': 'Rejected commission',
  'grossSale': 'Gross sale',
  'grossConversionCount': 'Gross conversions',
  'ecr': 'ECR',
  'epc': 'EPC',
  'epm': 'EPM',
  'networks': 'Networks',
  'trackers': 'Campaigns',
  'assignees': 'Assigned',
  'advertiserStatus': 'Status',
  'advertiserStatusPartner': 'Partner level',
  'advertiserStatusDeal': 'Deal type',
}

export const initialPage = 75
export const perPage = 50
