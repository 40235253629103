import React, { useState, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { groupBy, uniq, sortBy } from 'lodash'
import { toUnicode } from 'punycode'
import { startOfDay, addDays, endOfDay } from 'date-fns'
import * as lz from 'lz-string'
import SVG from 'react-inlinesvg'
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd'
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend'
import ReactTooltip from 'react-tooltip'
import {
  availableGroupBy,
  colors,
  columnsDesc,
  dateDiffFormats,
  dateDiffUnits,
  defaultGroupBy,
  defaultTableColumns,
  filterLabels,
  filterOptions,
  groupFilterKeys,
  groupLabels,
  hideChannelColumns,
  hideMerchantColumns,
  hideNetworkColumns,
  hidePageviewColumns,
  hideTrackerColumns,
  initialPage,
  perPage,
  tableColumnsKey,
  tableLabels,
} from './constants'
import { StatisticsContextProvider, useStatistics } from './context'
import { Placeholder } from './Placeholder'
import { PerformanceAreaChart } from './AreaChart'
import { PerformanceBarChart } from './BarChart'
import {
  IInsightsV1Statistics,
  INetworksV1List,
  IProfileV1Users,
  Header,
  DatePicker,
  NavFilter,
  TableInfo,
  TableColumns,
  TableEmpty,
  useDatePickerState,
  Search,
  Loadable,
  useApiGet,
  useWindowSize,
  useIsOverflowX,
  useTableEmpty,
  sumTrackerRows,
  calculateValues,
  formatFilterRecords,
  useSearch,
  filterTableColumns,
  navigateTo,
  parseQuery,
  dateDiff,
  varClass,
  isBlank,
  map,
  plural,
  downloadCsv,
  useOutsideClick,
  copyToClipboard,
  useSnackbar,
  truncate,
  SmallLoadable,
  capitalize,
  useCounter,
  AdvertiserStatus,
  AssigneeList,
} from '../../shared'
import {
  getCurrentUser,
  getCurrentCurrency,
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../services'
import {
  formatFilterDates,
  findFavorite,
  normalizeFilter,
  normalizeGroupBy,
  statisticsCustomFilter,
  statisticsPaginate,
  statisticsSearch,
  statisticsSort,
  sumRows,
  filterCsvColumns,
  newCsvRowFormatter,
} from './helpers'

export const InsightStatistics = (props) => {
  const { origin } = props

  const datePickerState = useDatePickerState()
  const currencyCode = getCurrentCurrency()

  const initialLoad = useRef({ loaded: false })
  const initialQuery = useMemo(() => parseQuery(location.search), [])
  const initialGroup = useMemo(() => {
    if (isBlank(initialQuery)) {
      return defaultGroupBy
    } else if (
      initialQuery.tab &&
      availableGroupBy.includes(initialQuery.tab)
    ) {
      return initialQuery.tab
    } else {
      return defaultGroupBy
    }
  }, [])
  const initialFilters = useMemo(() => {
    if (isBlank(initialQuery)) {
      return []
    } else if (initialQuery.filters) {
      try {
        const initialFilters = lz.decompressFromEncodedURIComponent(
          initialQuery.filters
        )
        if (initialFilters === null) throw 'Incorrect lz-string'
        return JSON.parse(initialFilters)
      } catch (e) {
        try {
          // Backward compatibility
          return JSON.parse(window.decodeURIComponent(initialQuery.filters))
        } catch (e) {
          return []
        }
      }
    } else {
      return []
    }
  }, [])

  useEffect(() => {
    // Special case for "Decreasing performers"
    if (initialQuery?.ecrFilter) {
      datePickerState.setRange({
        ...datePickerState.range,
        startDate: startOfDay(addDays(new Date(), -4)),
        endDate: endOfDay(addDays(new Date(), -1)),
      })
      datePickerState.setPrevRange({
        ...datePickerState.range,
        startDate: startOfDay(addDays(new Date(), -30)),
        endDate: endOfDay(addDays(new Date(), -5)),
      })
      datePickerState.setPrevRangeEnabled(true)
    }
  }, [initialQuery])

  const [prevData, setPrevData] = useState<IInsightsV1Statistics>(null)
  const [data, setData] = useState<IInsightsV1Statistics>(null)
  const [favorites, setFavorites] = useState(null)
  const [favoritesRef, refreshFavorites] = useCounter()
  const [assignees, setAssignees] = useState(null)
  const [assigneesRef, refreshAssignees] = useCounter()
  const [statuses, setStatuses] = useState(null)
  const [statusesRef, refreshStatuses] = useCounter()
  const [group, setGroup] = useState(initialGroup)
  const [filters, setFilters] = useState(initialFilters)
  const [selectedGroupByTime, setSelectedGroupByTime] = useState('')
  const [trafficFilter, setTrafficFilter] = useState(false)

  const [search, setSearch] = useSearch()

  const advertisers = useApiGet('/advs/v1/minlist')
  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet<INetworksV1List[]>('/networks/v1/list')
  const urlShorts = useApiGet<any>('/tools/v1/urlshorts/list')
  const users = useApiGet<IProfileV1Users[]>('/profile/v1/users')

  useEffect(() => {
    if (group === 'date' || group === 'month' || group === 'year') {
      setGroup(selectedGroupByTime.toLowerCase())
    }
  }, [selectedGroupByTime])

  useEffect(() => {
    const queryGroup = `tab=${group}`
    const queryFilters = filters.length
      ? `&filters=${lz.compressToEncodedURIComponent(JSON.stringify(filters))}`
      : ''
    const newUrl = window.location.pathname + `?${queryGroup}${queryFilters}`
    history.replaceState({ path: newUrl }, '', newUrl)
  }, [group, filters])

  const filterReset = useMemo(() => {
    return sortBy(filters, (filter) => filter.key)
      .filter((filter) => filter.value || filter.key === 'advertiserStatus')
      .map((filter) => filter.value)
      .join(':')
  }, [filters])

  useEffect(() => {
    const fetchStatistics = async () => {
      setPrevData(null)
      setData(null)

      const groupBy = normalizeGroupBy(group, datePickerState)

      const enableCompare = datePickerState.prevRangeEnabled
      if (enableCompare) {
        const [result, prevResult] = await Promise.all([
          postRequest('/insights/v1/statistics', {
            groupBy,
            ...normalizeFilter({
              datePickerState,
              filters,
              origin,
              trafficFilter,
            }),
          }),
          postRequest('/insights/v1/statistics', {
            groupBy,
            ...normalizeFilter({
              datePickerState,
              filters,
              origin,
              trafficFilter,
              usePrevDates: true,
            }),
          }),
        ])

        setPrevData(prevResult?.data)
        setData(result?.data)
      } else {
        const result = await postRequest('/insights/v1/statistics', {
          groupBy,
          ...normalizeFilter({
            datePickerState,
            filters,
            origin,
            trafficFilter,
          }),
        })

        setData(result?.data)
      }
    }

    fetchStatistics()
  }, [group, datePickerState, filterReset, trafficFilter])

  useEffect(() => {
    const fetchFavorites = async () => {
      const favorites = await getRequest('/insights/v1/favoritestatistics')
      setFavorites(favorites?.data || [])
    }

    fetchFavorites()
  }, [favoritesRef])

  useEffect(() => {
    const fetchAssignees = async () => {
      const assignees = await getRequest('/advs/v1/assignees')
      setAssignees(assignees?.data || [])
    }

    fetchAssignees()
  }, [assigneesRef])

  useEffect(() => {
    const fetchStatuses = async () => {
      const statuses = await getRequest('/advs/v1/status')
      setStatuses(statuses?.data || [])
    }

    fetchStatuses()
  }, [statusesRef])

  useEffect(() => {
    if (data?.rows?.length) {
      if (group === 'shortcode') {
        // Remove Unknown
        data.rows.splice(
          data.rows.findIndex((row) => row.grp === ''),
          1
        )
        // Replace shortcode with Smart link name
        if (urlShorts?.length) {
          for (const row of data.rows) {
            const urlShort = urlShorts.find(
              (urlShort) => urlShort.urlShortCode === row.grp
            )
            if (urlShort) {
              row.grp = urlShort.urlShortName
            }
          }
        }
      }
    }
  }, [data, urlShorts])

  const allAffiliateRef = useRef({ selected: false })
  useEffect(() => {
    if (!networks) return
    const networkFilter = filters.find(
      (filter) => filter.key === 'networkUuids'
    )
    if (!networkFilter) return
    const filterValues = networkFilter.value.split(', ')
    const allAffiliateUuids = networks
      .filter((network) => network.networkName !== 'Heylink Inbound')
      .map((network) => network.networkUuid)

    if (
      filterValues.includes('all') &&
      !allAffiliateUuids.every((uuid) => filterValues.includes(uuid)) &&
      !allAffiliateRef.current.selected
    ) {
      networkFilter.value = ['all', ...allAffiliateUuids].join(', ')
      setFilters([...filters])
      allAffiliateRef.current.selected = true
    } else if (
      !filterValues.includes('all') &&
      allAffiliateRef.current.selected
    ) {
      networkFilter.value = ''
      setFilters([...filters])
      allAffiliateRef.current.selected = false
    }

    if (filterValues.includes('all')) {
      networkFilter.label = 'All affiliate networks'
    }
  }, [filters])

  const [columns, setColumns] = useState(() =>
    filterTableColumns(
      localStorage.getItem(tableColumnsKey),
      defaultTableColumns,
      defaultTableColumns
    )
  )

  const availableColumns = useMemo(() => {
    switch (group) {
      case 'channel':
        return defaultTableColumns.filter(
          (c) => !hideChannelColumns.includes(c)
        )
      case 'name':
        return defaultTableColumns.filter(
          (c) => !hideMerchantColumns.includes(c)
        )
      case 'currency':
        return defaultTableColumns.filter(
          (c) => !hidePageviewColumns.includes(c)
        )
      case 'tracker':
        return defaultTableColumns.filter(
          (c) =>
            !hidePageviewColumns.includes(c) &&
            !hideNetworkColumns.includes(c) &&
            !hideTrackerColumns.includes(c)
        )
      case 'target':
      case 'network':
      case 'shortcode':
        return defaultTableColumns.filter(
          (c) =>
            !hidePageviewColumns.includes(c) && !hideNetworkColumns.includes(c)
        )
      default:
        return defaultTableColumns.filter(
          (c) => !hideNetworkColumns.includes(c)
        )
    }
  }, [group])

  useEffect(() => {
    if (initialLoad.current.loaded) {
      setSearch('')
    } else {
      initialLoad.current.loaded = true
    }
  }, [group])

  const [crFilter, setCrFilter] = useState(initialQuery?.crFilter)
  const [ecrFilter, setEcrFilter] = useState(initialQuery?.ecrFilter)
  const [favFilter, setFavFilter] = useState(false)
  const [showGraph, setShowGraph] = useState(false)
  const [graphHeight, setGraphHeight] = useState(0)
  const [graphColumns, setGraphColumns] = useState([
    'commission',
    'conversions',
    'clicks',
    'pageviews',
  ])

  const contextValue = {
    filters,
    setFilters,
    datePickerState,
    advertisers,
    channels,
    networks,
    users,
    search,
    setSearch,
    data,
    prevData,
    favorites,
    setFavorites,
    refreshFavorites,
    assignees,
    setAssignees,
    refreshAssignees,
    statuses,
    setStatuses,
    refreshStatuses,
    group,
    setGroup,
    columns,
    setColumns,
    availableColumns,
    crFilter,
    setCrFilter,
    ecrFilter,
    setEcrFilter,
    favFilter,
    setFavFilter,
    trafficFilter,
    setTrafficFilter,
    showGraph,
    setShowGraph,
    graphHeight,
    setGraphHeight,
    graphColumns,
    setGraphColumns,
    currencyCode,
    setSelectedGroupByTime,
    selectedGroupByTime,
  }

  return (
    <>
      <Header
        label={origin ? `${capitalize(origin)} Statistics` : 'All Statistics'}
      />
      <StatisticsContextProvider value={contextValue}>
        <Nav />
        <Loadable
          loaded={data && favorites && assignees && statuses}
          placeholder={<Placeholder />}
        >
          <DndProvider backend={HTML5Backend}>
            <Stats />
          </DndProvider>
          <DatePicker className="in-header" enableCompare />
        </Loadable>
      </StatisticsContextProvider>
    </>
  )
}

const Nav = (props) => {
  const {
    filters,
    setFilters,
    datePickerState,
    advertisers,
    channels,
    networks,
    users,
  } = useStatistics()

  // 'advertiser'|'channel'|'page'|'device'|'target'|'network'|'referer'|'connection'|'tracker'|'shortcode'|'status'|'currency'|'date'|'week'|'month'|'year'

  const dateOptions = useMemo(
    () => formatFilterDates(datePickerState),
    [datePickerState]
  )

  return (
    <>
      <div className="page-nav m-b-4">
        <NavFilter
          {...{
            filters,
            setFilters,
            filterLabels,
            filterOptions: {
              date: dateOptions,
              ...filterOptions,
              names: formatFilterRecords(
                advertisers,
                'advertiserDomain',
                'advertiserDomain'
              ),
              channelUuids: formatFilterRecords(
                channels,
                'channelUuid',
                'channelName',
                'channelDomain'
              ),
              networkUuids: {
                'all': 'All affiliate networks',
                ...formatFilterRecords(networks, 'networkUuid', 'networkName'),
              },
              assigneeUserUuids: formatFilterRecords(
                users,
                'userUuid',
                (user) => `${user.userFirstname} ${user.userLastname}`
              ),
              'subIds': 'input',
              'subId1s': 'input',
              'subId2s': 'input',
              'subId3s': 'input',
              'subId4s': 'input',
              'subId5s': 'input',
              'tags': 'pair',
            },
          }}
          isMultipleChoice
          noMultipleChoice={['date']}
        />
      </div>
    </>
  )
}

const GroupItem = (props) => {
  const { title, value, values, group, setGroup } = props

  return (
    <li
      className={
        group === value || values?.some((v) => v === group) ? 'active' : ''
      }
    >
      <button
        className="link"
        onClick={() => {
          setGroup(value)
        }}
      >
        {title}
      </button>
    </li>
  )
}

const Stats = (props) => {
  const {
    data,
    prevData,
    group,
    setGroup,
    filters,
    showGraph,
    graphHeight,
    datePickerState,
  } = useStatistics()

  const [sort, setSort] = useState('conversionTotalCommission')
  const [dir, setDir] = useState('desc')
  const [prevSort, setPrevSort] = useState('')
  const [prevDir, setPrevDir] = useState('desc')
  const [unknownRow, setUnknownRow] = useState(null)
  const [isOverflow, setIsOverflow] = useState(true)
  const [total, setTotal] = useState(0)
  const toggleSort = (newSort: string, defaultDir: 'asc' | 'desc') => () => {
    if (sort === newSort) {
      if (dir === 'asc') {
        setDir('desc')
      } else {
        setDir('asc')
      }
    } else {
      setPrevSort(sort)
      setPrevDir(dir)
      setSort(newSort)
      setDir(defaultDir)
    }
  }

  if (!data.totals) {
    return null
  }

  useEffect(() => {
    const newUnknownRow = data?.rows?.find(
      (item) =>
        (!item.name && !item.grp) ||
        (item.name && item.name.toLowerCase() === 'unknown')
    )
    const newPrevUnknownRow = prevData?.rows?.find(
      (item) =>
        (!item.name && !item.grp) ||
        (item.name && item.name.toLowerCase() === 'unknown')
    )
    if (newUnknownRow && newPrevUnknownRow) {
      newUnknownRow.prev = newPrevUnknownRow
    }
    setUnknownRow(newUnknownRow)
  }, [data])

  // const isRevshareSource = useMemo(
  //   () => getCurrentUser()?.user?.userAccountRevshareSource,
  //   []
  // )

  const isSingleDate =
    group === 'date' &&
    moment(datePickerState.range.startDate).format('YYYY-MM-DD') ===
      moment(datePickerState.range.endDate).format('YYYY-MM-DD')

  const groupItemProps = { group, setGroup, filters }

  return (
    <div
      className={varClass({
        'card insights-statistics-card': true,
        'show-graph': showGraph,
      })}
      style={
        {
          '--graph-height': `${graphHeight}px`,
        } as any
      }
    >
      <div className="page-subnav">
        <div className="nav">
          <ul>
            <GroupItem title="Merchant" value="name" {...groupItemProps} />
            <GroupItem title="Campaign" value="tracker" {...groupItemProps} />
            <GroupItem title="Site" value="channel" {...groupItemProps} />
            <GroupItem title="Network" value="network" {...groupItemProps} />
            <GroupItem
              title="Smart link"
              value="shortcode"
              {...groupItemProps}
            />
            <li className="divider" />
            <GroupItem
              title="Date"
              value="date"
              values={['date', 'month', 'year']}
              {...groupItemProps}
            />
            <GroupItem title="Target" value="target" {...groupItemProps} />
            <GroupItem
              title="Network account"
              value="connection"
              {...groupItemProps}
            />
            <GroupItem title="Page" value="page" {...groupItemProps} />
            <GroupItem title="Device" value="device" {...groupItemProps} />
            <GroupItem title="Currency" value="currency" {...groupItemProps} />
            {/*
            <GroupItem title="Referrer" value="referer" {...groupItemProps} />
            {isRevshareSource && (
              <GroupItem title="Boost" value="revshare" {...groupItemProps} />
            )}
            */}
          </ul>
        </div>
      </div>

      <div className="card-divider" />
      <div className="card-body card-body-fill">
        <StatNav
          {...{
            total,
          }}
        />
        <Graph
          {...{
            isSingleDate,
          }}
        />
        <Table
          {...{
            sort,
            dir,
            prevSort,
            prevDir,
            toggleSort,
            isSingleDate,
            unknownRow,
            setIsOverflow,
            setTotal,
          }}
        />
      </div>
    </div>
  )
}

const StatNav = (props) => {
  const { sort, dir, prevSort, prevDir, total } = props
  const {
    data,
    prevData,
    group,
    search,
    setSearch,
    columns,
    setColumns,
    availableColumns,
    filters,
    favorites,
    networks,
    assignees,
    statuses,
    crFilter,
    setCrFilter,
    ecrFilter,
    setEcrFilter,
    favFilter,
    setFavFilter,
    trafficFilter,
    setTrafficFilter,
    showGraph,
    setShowGraph,
    graphColumns,
    setGraphColumns,
    datePickerState,
    currencyCode,
    setSelectedGroupByTime,
    selectedGroupByTime,
  } = useStatistics()

  return (
    <TableInfo>
      {showGraph ? (
        <>
          <div className="graph-columns">
            {group === 'date' && (
              <GraphSwitch
                title="Pageviews"
                value="pageviews"
                selectedValues={graphColumns}
                setValues={setGraphColumns}
                color={colors.pageviews}
              />
            )}
            <GraphSwitch
              title="Clicks"
              value="clicks"
              selectedValues={graphColumns}
              setValues={setGraphColumns}
              color={colors.clicks}
            />
            <GraphSwitch
              title="Conversions"
              value="conversions"
              selectedValues={graphColumns}
              setValues={setGraphColumns}
              color={colors.conversions}
            />
            <GraphSwitch
              title="Revenue"
              value="commission"
              selectedValues={graphColumns}
              setValues={setGraphColumns}
              color={colors.commission}
            />
          </div>
        </>
      ) : (
        <Search
          value={search}
          setValue={setSearch}
          placeholder={`Find ${groupLabels[group]?.toLowerCase()}`}
          className="search flex-1"
        />
      )}
      <div className="vertical-middle">
        <TableColumns
          storageKey={tableColumnsKey}
          columns={columns}
          setColumns={setColumns}
          availableColumns={availableColumns}
          columnLabels={tableLabels}
        />
        <button
          className="link action m-l-15"
          onClick={() => {
            const rows: any = statisticsSort(
              statisticsCustomFilter({
                data: data.rows,
                prevData: prevData?.rows,
                search,
                filters,
                favorites,
                assignees,
                statuses,
                group,
                crFilter,
                ecrFilter,
                favFilter,
              }),
              [
                { sort: prevSort, dir: prevDir },
                { sort, dir },
              ]
            )
            const csvColumns = filterCsvColumns(columns, availableColumns)
            downloadCsv<any>({
              filename: `${group}_${moment().format('YYYY-MM-DD')}.csv`,
              columns: csvColumns,
              rows,
              columnFormatter: (column) =>
                tableLabels[column] || groupLabels[group],
              rowFormatter: newCsvRowFormatter({
                group,
                columns: csvColumns,
                statuses,
                networks,
                assignees,
                currencyCode,
              }),
            })
          }}
        >
          <SVG src="/images/insights/icon-csv.svg" className="m-r-10" />
          CSV
        </button>
        <button
          className={varClass({
            'link action m-l-15': true,
            'active': crFilter,
          })}
          onClick={() => setCrFilter(!crFilter)}
        >
          <SVG src="/images/aside-conversions.svg" className="m-r-10" />
          &lt;0,8% CR
        </button>
        {datePickerState.prevRangeEnabled && (
          <button
            className={varClass({
              'link action m-l-15': true,
              'active': ecrFilter,
            })}
            onClick={() => setEcrFilter(!ecrFilter)}
          >
            <SVG src="/images/aside-conversions.svg" className="m-r-10" />
            -15% ECR
          </button>
        )}
        <button
          className={varClass({
            'link action m-l-15': true,
            'active': showGraph,
          })}
          onClick={() => setShowGraph(!showGraph)}
        >
          <SVG src="/images/insights/icon-graph.svg" className="m-r-10" />
          Graph
        </button>
        {group === 'name' && (
          <button
            className={varClass({
              'link action m-l-15': true,
              'active': favFilter,
              'hidden': isBlank(favorites),
            })}
            onClick={() => setFavFilter(!favFilter)}
          >
            {favFilter ? (
              <SVG
                src="/images/icon-star-filled.svg"
                className="m-r-10 text-new-orange"
              />
            ) : (
              <SVG src="/images/icon-star.svg" className="m-r-10" />
            )}
            Favorites
          </button>
        )}
        <button
          className={varClass({
            'link action m-l-15': true,
            'active': trafficFilter,
          })}
          onClick={() => setTrafficFilter(!trafficFilter)}
        >
          <SVG src="/images/insights/icon-swap.svg" className="m-r-1" />
          Split tests
        </button>
        {(group === 'date' || group === 'month' || group === 'year') && (
          <GroupByTime
            className={'m-l-15'}
            group={group}
            setSelected={setSelectedGroupByTime}
            selected={selectedGroupByTime}
          />
        )}
        <div className="total">
          {total} {plural(total, 'result')}
        </div>
      </div>
    </TableInfo>
  )
}

const Table = (props) => {
  const {
    sort,
    dir,
    prevSort,
    prevDir,
    toggleSort,
    isSingleDate,
    unknownRow,
    setIsOverflow,
    setTotal,
  } = props
  const {
    data,
    prevData,
    networks,
    assignees,
    statuses,
    group,
    search,
    filters,
    setFilters,
    favorites,
    setFavorites,
    refreshFavorites,
    columns,
    setColumns,
    availableColumns,
    crFilter,
    ecrFilter,
    favFilter,
    datePickerState,
    currencyCode,
  } = useStatistics()

  const [draggedColumn, setDraggedColumn] = useState('')

  const swapColumns = (value1: string, value2: string) => {
    const index1 = columns.indexOf(value1)
    const index2 = columns.indexOf(value2) || 1
    if (index1 === index2) return
    if (index1 === 0 || index2 === 0) return
    const newColumns = [...columns]
    const item = newColumns[index1]
    newColumns.splice(index1, 1)
    newColumns.splice(index2, 0, item)
    setColumns(newColumns)
    localStorage.setItem(tableColumnsKey, newColumns.join(','))
  }

  const shownColumns = columns.filter((c) => availableColumns.includes(c))

  const [isEmpty, setIsEmpty] = useTableEmpty(data.rows)

  const dataRows = useMemo(() => {
    if (!data?.rows?.length) return []

    if (group === 'tracker') {
      // Group rows by matching name
      const rowsByName = groupBy(data.rows, (row) => row.name)
      const prevRowsByName = prevData
        ? groupBy(prevData.rows, (row) => row.name)
        : {}
      return Object.keys(rowsByName).map((name) => {
        return {
          ...sumTrackerRows(rowsByName[name]),
          prev: prevRowsByName[name]?.length
            ? sumTrackerRows(prevRowsByName[name])
            : undefined,
        }
      })
    } else if (prevData) {
      return data.rows.map((row) => {
        let adjustGrp = (value) => value
        if (['date', 'hour', 'week', 'month', 'year'].includes(group)) {
          const sameDate =
            moment(datePickerState.range.startDate).format('YYYY-MM-DD') ===
            moment(datePickerState.range.endDate).format('YYYY-MM-DD')
          const key = group === 'date' && sameDate ? 'hour' : group
          const t = dateDiffUnits[key]
          const f = dateDiffFormats[key]
          const diff = dateDiff(
            datePickerState.range.startDate,
            datePickerState.range.endDate,
            t
          )
          adjustGrp = (grp) => moment(grp, f).add(diff, t).add(1, t).format(f)
        }
        const prev = prevData.rows.find(
          (prevRow) => adjustGrp(prevRow.grp) === row.grp
        )
        return {
          ...row,
          prev,
        }
      })
    } else {
      return data.rows
    }
  }, [data.rows, prevData?.rows, group])

  const filteredRows = useMemo(
    () =>
      statisticsCustomFilter({
        data: dataRows,
        search,
        filters,
        favorites,
        assignees,
        statuses,
        group,
        crFilter,
        ecrFilter,
        favFilter,
      }),
    [
      dataRows,
      search,
      filters,
      favorites,
      assignees,
      statuses,
      group,
      crFilter,
      ecrFilter,
      favFilter,
    ]
  )
  const sortedRows = useMemo(
    () =>
      statisticsSort(filteredRows, [
        { sort: prevSort, dir: prevDir },
        { sort, dir },
      ]),
    [filteredRows, sort, dir, prevSort, prevDir]
  )

  const [page, setPage] = useState(0)
  const hasMore = initialPage + page * perPage < filteredRows.length
  const loadMore = () => setPage(page + 1)
  const loadMoreScroll = (event) => {
    if (hasMore) {
      const element = event.target
      const bottom = element.scrollHeight - element.offsetHeight - 100
      const isScrolledToBottom = element.scrollTop >= bottom
      if (isScrolledToBottom) {
        loadMore()
      }
    }
  }
  useEffect(() => {
    if (page > 0) {
      setPage(0)
    }
    const table = document.querySelector('.insights-statistics-table')
    if (table) {
      const element = table.parentElement
      element.scrollTo(0, 0)
    }
  }, [data.rows, search, group, sort, dir])

  const shownRows = statisticsPaginate(sortedRows, initialPage + page * perPage)

  useEffect(() => {
    setTotal(shownRows?.length ?? 0)
  }, [shownRows])

  const ref = useRef<HTMLDivElement>()
  const [overflowRows, setOverflowRows] = useState(0)
  useEffect(() => {
    if (shownRows.length) {
      if (ref.current) {
        const availableHeight = ref.current.offsetHeight - shownRows.length * 64
        const newOverflowRows = Math.floor(availableHeight / 64) - 1
        if (overflowRows !== newOverflowRows) {
          setOverflowRows(newOverflowRows)
        }
      }
    } else {
      setOverflowRows(0)
    }
  }, [shownRows, group])

  const windowSize = useWindowSize()
  const isOverflowX = useIsOverflowX(ref, undefined, [
    group,
    search,
    columns,
    shownRows,
    windowSize,
  ])

  const totalValues = useMemo(() => {
    if (search) {
      return calculateValues(sumRows([...filteredRows]), currencyCode)
    } else {
      return calculateValues(
        sumRows([...filteredRows, unknownRow]),
        currencyCode
      )
    }
  }, [search, filteredRows, unknownRow, currencyCode])
  // const totalValues = calculateValues(data.totals)

  useEffect(() => {
    setIsOverflow(isOverflowX)
  }, [isOverflowX])

  return (
    <>
      <div className="insights-statistics-table-container">
        <div
          className="table-container-overflow"
          ref={ref}
          onScroll={loadMoreScroll}
        >
          <table className="table table-bordered table-sortable table-hoverable insights-statistics-table">
            <thead className="text-nowrap">
              <tr>
                {shownColumns.map((column, index) => {
                  return (
                    <TableHead
                      key={column}
                      index={index}
                      value={column}
                      label={tableLabels[column] || groupLabels[group]}
                      desc={columnsDesc.includes(column)}
                      {...{
                        toggleSort,
                        sort,
                        dir,
                        columns,
                        draggedColumn,
                        setDraggedColumn,
                        swapColumns,
                      }}
                    />
                  )
                })}
              </tr>
            </thead>
            <tbody className="text-nowrap">
              {map(shownRows, (row, index) => {
                if (
                  (!row.name && !row.grp) ||
                  (row.name && row.name.toLowerCase() === 'unknown')
                ) {
                  return
                }
                return (
                  <TableItem
                    key={row.grp || row.name || index}
                    {...{
                      row,
                      networks,
                      assignees,
                      statuses,
                      sort,
                      dir,
                      group,
                      filters,
                      setFilters,
                      columns: shownColumns,
                      draggedColumn,
                      favorites,
                      setFavorites,
                      refreshFavorites,
                      datePickerState,
                      currencyCode,
                      isSingleDate,
                    }}
                  />
                )
              })}
              {!isEmpty && isBlank(shownRows) && (
                <tr>
                  <td
                    colSpan={shownColumns?.length ?? 10}
                    className="text-center text-light text-bolder"
                  >
                    <div className="max-w-100vw">Nothing found</div>
                  </td>
                </tr>
              )}
              {overflowRows > 0 &&
                map(overflowRows, (_, i) => (
                  <tr key={`overflow-${i}`} className="blank-row">
                    <td colSpan={10} />
                  </tr>
                ))}
            </tbody>
            {!isBlank(data.totals) && (
              <tfoot
                className={varClass({
                  'no-overflow': !isOverflowX,
                })}
              >
                {unknownRow && !search ? (
                  <TableItem
                    {...{
                      row: unknownRow,
                      sort: null,
                      dir,
                      group,
                      filters,
                      setFilters,
                      columns: shownColumns,
                      draggedColumn,
                      favorites,
                      setFavorites,
                      refreshFavorites,
                      datePickerState,
                      currencyCode,
                      isSingleDate,
                      hideFilter: true,
                      tooltipLabel: (
                        <>
                          <div style={{ textAlign: 'left' }}>
                            <p style={{ fontWeight: 400 }}>
                              Conversion details could be missing if:
                            </p>
                            <ul
                              style={{
                                listStyle: 'none',
                                padding: 0,
                                fontStyle: 'italic',
                                fontWeight: 400,
                              }}
                            >
                              <li>- Clicks are not sent through Heylink.</li>
                              <li>
                                - The Heylink script is not installed correctly.
                              </li>
                              <li>
                                - Historic conversion data from before you
                                actived Heylink was imported from your networks.
                              </li>
                            </ul>
                          </div>
                        </>
                      ),
                      isUnknownRow: true,
                    }}
                  />
                ) : null}
                <tr className="text-nowrap">
                  <th className="text-dark text-bolder background-new-gray-light sticky left-0 z-2">
                    <div className="first-column">Total</div>
                  </th>
                  {shownColumns.map((column, index) =>
                    index === 0 ? null : (
                      <th
                        key={column}
                        className={varClass({
                          'text-right background-new-gray-light': true,
                          'drag-highlight': draggedColumn === column,
                        })}
                      >
                        <TableValue values={totalValues} column={column} />
                      </th>
                    )
                  )}
                </tr>
              </tfoot>
            )}
          </table>
          <ReactTooltip
            id="table-tooltip"
            type="dark"
            effect="solid"
            place="bottom"
          />
          <DragTable
            {...{
              data,
              shownRows,
              group,
              columns: shownColumns,
              filters,
              datePickerState,
              currencyCode,
            }}
          />
        </div>
      </div>

      {isEmpty && (
        <TableEmpty
          icon="insights"
          title="No data available yet"
          subtitle="To start using Reports simply connect a network"
          buttons={{
            '/networks': 'Connect network',
          }}
          setIsEmpty={setIsEmpty}
        />
      )}
    </>
  )
}

const TableHead = (props) => {
  const {
    value,
    label,
    desc,
    sort,
    dir,
    toggleSort,
    index,
    columns,
    draggedColumn,
    setDraggedColumn,
    swapColumns,
    hideSort,
  } = props

  const ref = useRef(null)

  const [{ isDragging }, dragHandle, drag] = useDrag(() => ({
    type: 'column',
    item: () => {
      setDraggedColumn(value)
      return { value }
    },
    end: (item) => {
      setDraggedColumn('')
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [_props, drop] = useDrop({
    accept: 'column',
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const value1 = item.value
      const value2 = value
      if (value1 === value2) {
        return
      }
      const index1 = columns.indexOf(value1)
      const index2 = columns.indexOf(value2)

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const hoverQuarterX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 4

      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left

      if (index1 < index2 && hoverClientX < hoverMiddleX - hoverQuarterX) {
        return
      }
      if (index1 > index2 && hoverClientX > hoverMiddleX + hoverQuarterX) {
        return
      }

      swapColumns(value1, value2)
    },
    drop: (item: any) => {
      swapColumns(item.value, value)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  drag(getEmptyImage())
  drop(ref)

  if (index === 0) {
    return (
      <>
        <th
          onClick={() => {
            if (!hideSort) {
              toggleSort(value, desc ? 'desc' : 'asc').call(this)
            }
          }}
          ref={ref}
          className={varClass({
            'vertical-middle sticky left-0 z-2': true,
            'sort-highlight': sort === value,
          })}
        >
          <div className="first-column p-r-4">
            {draggedColumn && (
              <div
                className={varClass({
                  'drop-space': true,
                  'opaque': draggedColumn === columns[index],
                })}
              />
            )}
            <div className="row row-fill row-nowrap">
              <span
                className={varClass({
                  'column-label': true,
                  'cursor-default': hideSort,
                })}
              >
                {label}
                {!hideSort && (
                  <>
                    {sort === value ? (
                      <>
                        {dir === 'desc' ? (
                          <SVG
                            src="/images/insights/caret-down.svg"
                            className="m-l-1"
                          />
                        ) : (
                          <SVG
                            src="/images/insights/caret-up.svg"
                            className="m-l-1"
                          />
                        )}
                      </>
                    ) : (
                      <SVG src="/images/insights/caret.svg" className="m-l-1" />
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
        </th>
      </>
    )
  } else {
    return (
      <th
        onClick={() => {
          if (!hideSort) {
            toggleSort(value, desc ? 'desc' : 'asc').call(this)
          }
        }}
        ref={ref}
        className={varClass({
          'relative vertical-middle p-l-2 p-r-4': true,
          'sort-highlight': sort === value,
          'drag-highlight': draggedColumn === value,
        })}
        style={{
          color: isDragging ? 'transparent' : '',
        }}
      >
        {draggedColumn && <div className="drop-space" />}
        <div className="row row-fill row-nowrap">
          <span ref={dragHandle} className="cursor-grab m-r-10">
            <SVG src="/images/insights/icon-drag.svg" className="handle" />
          </span>
          <span
            className={varClass({
              'column-label': true,
              'cursor-default': hideSort,
            })}
          >
            {label}
            {label === 'Pending commission' && (
              <>
                <span
                  data-tip="Part of total commission that's still pending approval"
                  data-for="pend-tooltip"
                  className="m-x-1"
                >
                  <SVG src="/images/recommendations/icon-info.svg" />
                </span>
                <ReactTooltip
                  id="pend-tooltip"
                  type="dark"
                  effect="solid"
                  place="right"
                />
              </>
            )}
            {label === 'ECR' && (
              <>
                <span
                  data-tip="Effective Commission Rate"
                  data-for="ecr-tooltip"
                  className="m-x-1"
                >
                  <SVG src="/images/recommendations/icon-info.svg" />
                </span>
                <ReactTooltip
                  id="ecr-tooltip"
                  type="dark"
                  effect="solid"
                  place="right"
                />
              </>
            )}

            {!hideSort && (
              <>
                {sort === value ? (
                  <>
                    {dir === 'desc' ? (
                      <SVG
                        src="/images/insights/caret-down.svg"
                        className="m-l-1"
                      />
                    ) : (
                      <SVG
                        src="/images/insights/caret-up.svg"
                        className="m-l-1"
                      />
                    )}
                  </>
                ) : (
                  <SVG src="/images/insights/caret.svg" className="m-l-1" />
                )}
              </>
            )}
          </span>
        </div>
      </th>
    )
  }
}

const TableItem = (props) => {
  const {
    row,
    networks,
    assignees,
    statuses,
    sort,
    group,
    columns,
    draggedColumn,
    favorites,
    setFavorites,
    refreshFavorites,
    filters,
    setFilters,
    currencyCode,
    isSingleDate,
    hideFilter,
    tooltipLabel,
    isUnknownRow,
  } = props

  const { grp, name } = row
  const values = useMemo(
    () => calculateValues(row, currencyCode),
    [row, currencyCode]
  )
  const favorite = findFavorite(favorites, group, name, grp)

  const { showSnackbar } = useSnackbar()

  const [displayName, setDisplayName] = useState(name)

  useEffect(() => {
    if (name && name.toLowerCase() === 'unknown') {
      setDisplayName('Conversion details not available')
    }
  }, [name])

  return (
    <>
      <tr>
        <td
          className={varClass({
            'text-dark text-bold text-nowrap sticky left-0 background-white':
              true,
            'z-1': !isUnknownRow,
            'z-2': isUnknownRow,
          })}
          style={{ maxWidth: '380px' }}
        >
          <div className="first-column">
            <div className="name row row-center row-space-between row-nowrap vertical-middle gap-4">
              <span>
                <span
                  data-tip={
                    (displayName || grp).length > 35 ? displayName || grp : ''
                  }
                  data-for="table-tooltip"
                >
                  {truncate(
                    toUnicode(
                      displayName || grp || 'Conversion details not available'
                    ),
                    35
                  )}
                </span>
                {group === 'date' && isSingleDate && ':00'}
                {!!tooltipLabel && (
                  <>
                    <span
                      data-tip
                      data-for="statistic-tooltip"
                      className="m-l-1"
                    >
                      <SVG src="/images/recommendations/icon-info.svg" />
                    </span>
                    <ReactTooltip
                      id="statistic-tooltip"
                      type="dark"
                      effect="solid"
                      place="bottom"
                    >
                      {tooltipLabel}
                    </ReactTooltip>
                  </>
                )}
                {row.prev && (
                  <>
                    {['date', 'hour', 'week', 'month', 'year'].includes(
                      group
                    ) && (
                      <div className="text-smaller text-light o-60 m-t-1">
                        {row.prev.grp}
                        {group === 'date' && isSingleDate && ':00'}
                      </div>
                    )}
                  </>
                )}
              </span>
              {!hideFilter && (
                <div className="row row-narrow row-nowrap">
                  {group === 'name' && (
                    <>
                      {favorite ? (
                        <button
                          className="link link-filter text-new-orange"
                          onClick={async () => {
                            if (favorite.favoriteUuid) {
                              setFavorites(
                                favorites.filter(
                                  (fav) =>
                                    fav.favoriteUuid !== favorite.favoriteUuid
                                )
                              )
                              await deleteRequest(
                                '/insights/v1/favoritestatistics',
                                {
                                  favoriteUuid: favorite.favoriteUuid,
                                }
                              )
                              refreshFavorites()
                            }
                          }}
                        >
                          <SVG
                            src="/images/icon-star-filled.svg"
                            className="text-new-orange"
                          />
                        </button>
                      ) : (
                        <button
                          className="link link-filter"
                          onClick={async () => {
                            setFavorites([
                              ...favorites,
                              {
                                favoriteGroupBy: group,
                                favoriteGroupName: displayName || grp,
                              },
                            ])
                            await postRequest(
                              '/insights/v1/favoritestatistics',
                              {
                                favoriteGroupBy: group,
                                favoriteGroupName: displayName || grp,
                              }
                            )
                            refreshFavorites()
                          }}
                        >
                          <SVG src="/images/icon-star.svg" />
                        </button>
                      )}
                    </>
                  )}
                  {(group === 'page' || group === 'target') && (
                    <>
                      <button
                        className="link link-filter"
                        onClick={() => {
                          copyToClipboard(
                            toUnicode(
                              displayName ||
                                grp ||
                                'Conversion details not available'
                            )
                          )
                          showSnackbar('Copied to clipboard!')
                        }}
                      >
                        <SVG
                          src="/images/icon-copy.svg"
                          className="vertical-middle"
                        />
                      </button>
                      <a
                        href={`https://${toUnicode(
                          displayName ||
                            grp ||
                            'Conversion details not available'
                        )}`}
                        target="_blank"
                        className="link link-filter"
                      >
                        <SVG
                          src="/images/icon-outside-new.svg"
                          className="vertical-middle"
                        />
                      </a>
                    </>
                  )}
                  {group === 'name' && (
                    <button
                      className="link link-filter"
                      onClick={async (event) => {
                        const response = await getRequest(
                          `/advs/v1/uuid/${row.name || row.grp}`
                        )
                        if (response.data) {
                          navigateTo(event, `/merchants/${response.data}`)
                        }
                      }}
                    >
                      <SVG
                        src="/images/icon-details.svg"
                        className="vertical-middle"
                      />
                    </button>
                  )}
                  <button
                    className="link link-filter"
                    onClick={(event) => {
                      const rawValue = grp || displayName || ''
                      const rawLabel =
                        displayName ||
                        grp ||
                        'Tracking details not available to Heylink'

                      const key = groupFilterKeys[group] || group
                      const value =
                        key === 'date' || key === 'month' || key === 'year'
                          ? rawValue.split(' ')[0]
                          : rawValue
                      const label =
                        key === 'date' || key === 'month' || key === 'year'
                          ? rawLabel.split(' ')[0]
                          : rawLabel

                      const newFilters = [...filters]
                      const existingFilter = newFilters.find(
                        (filter) => filter.key === key
                      )
                      if (existingFilter) {
                        existingFilter.key = key
                        existingFilter.value = value
                        existingFilter.label = label
                      } else {
                        newFilters.push({ key, value, label })
                      }

                      if (
                        event.ctrlKey ||
                        event.metaKey ||
                        event.button === 2
                      ) {
                        const queryGroup = `tab=${group}`
                        const queryFilters = `filters=${lz.compressToEncodedURIComponent(
                          JSON.stringify(newFilters)
                        )}`
                        const newUrl =
                          window.location.pathname +
                          `?${queryGroup}&${queryFilters}`
                        navigateTo(event, newUrl)
                      } else {
                        setFilters(newFilters)
                      }
                    }}
                  >
                    <SVG
                      src="/images/icon-filter.svg"
                      className="vertical-middle"
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </td>

        {columns.map((column, index) =>
          index === 0 ? null : (
            <td
              key={column}
              className={varClass({
                'text-right': true,
                'sort-highlight': sort === column,
                'drag-highlight': draggedColumn === column,
                'background-white text-nowrap': isUnknownRow,
              })}
            >
              <TableValue
                row={displayName || grp}
                {...{ values, column, networks, assignees, statuses }}
              />
            </td>
          )
        )}
      </tr>
      {(group === 'page' || group === 'target') && (
        <ReactTooltip
          id={displayName || grp}
          type="dark"
          effect="solid"
          place="bottom"
        >
          {toUnicode(displayName || grp || 'Conversion details not available')}
        </ReactTooltip>
      )}
      {group === 'page' && (
        <ReactTooltip
          id={displayName || grp}
          type="dark"
          effect="solid"
          place="bottom"
        >
          {toUnicode(displayName || grp || 'Conversion details not available')}
        </ReactTooltip>
      )}
    </>
  )
}

const TableValue = (props) => {
  const { values, row, column, networks, assignees, statuses } = props

  const noPrev = !values.prev || !values.prev[column]

  if (
    column === 'conversionCount' ||
    column === 'rejectedCount' ||
    column === 'pageviewCount' ||
    column === 'clickCount' ||
    column === 'grossConversionCount'
  ) {
    if (noPrev) {
      return (
        <div className={`value-badge value-badge-${column}`}>
          {values[column]}
        </div>
      )
    } else {
      return (
        <>
          <div className={`value-badge value-badge-${column}`}>
            {values[column]}
          </div>
          <div className="m-t-1">
            <div className={`value-badge value-badge-${column} value-badge-sm`}>
              {values.prev[column]}
            </div>
          </div>
        </>
      )
    }
  } else if (
    column === 'conversionCommission' ||
    column === 'conversionClickCommission' ||
    column === 'conversionTotalCommission' ||
    column === 'conversionPendingCommission' ||
    column === 'conversionRejectedCommission'
  ) {
    if (noPrev) {
      return (
        <div className="value-badge value-badge-conversionCommission text-nowrap">
          {values[column]}
        </div>
      )
    } else {
      return (
        <>
          <div className="value-badge value-badge-conversionCommission text-nowrap">
            {values[column]}
          </div>
          <div className="m-t-1">
            <div className="value-badge value-badge-conversionCommission value-badge-sm text-nowrap">
              {values.prev[column]}
            </div>
          </div>
        </>
      )
    }
  } else if (column === 'networks') {
    if (networks && values.networkUuids?.length) {
      const shownNetworks = networks.filter((network) =>
        values.networkUuids.includes(network.networkUuid)
      )
      return (
        <div className="row row-narrow row-nowrap">
          {map(shownNetworks, (network) => (
            <div
              key={network.networkUuid}
              className="badge text-smaller badge-hoverable text-bold"
              onClick={(event) => {
                navigateTo(event, `/networks/${network.networkUuid}`)
              }}
            >
              {network.networkName}
            </div>
          ))}
        </div>
      )
    } else {
      return null
    }
  } else if (column === 'trackers') {
    if (values.trackerUuids?.length) {
      return <TrackerList trackerUuids={values.trackerUuids} />
    } else {
      return null
    }
  } else if (column === 'assignees') {
    return <AssigneeList domain={row} assignees={assignees} />
  } else if (column === 'advertiserStatus') {
    const partnerStatus = statuses?.find(
      (advertiserStatus) =>
        advertiserStatus.advertiserStatusAdvertiserDomain === row &&
        advertiserStatus.advertiserStatusType === 'partner'
    )
    const dealStatus = statuses?.find(
      (advertiserStatus) =>
        advertiserStatus.advertiserStatusAdvertiserDomain === row &&
        advertiserStatus.advertiserStatusType === 'deal'
    )
    return (
      <div className="row row-narrow row-nowrap">
        {partnerStatus ? (
          <AdvertiserStatus
            advertiserUuid={partnerStatus.advertiserStatusAdvertiserUuid}
            initialStatus={partnerStatus.advertiserStatusValue}
            statusType={partnerStatus.advertiserStatusType}
            tooltipId="table-tooltip"
          />
        ) : (
          <AdvertiserStatus
            advertiserDomain={row}
            initialStatus={''}
            statusType="partner"
            tooltipId="table-tooltip"
          />
        )}
        {dealStatus ? (
          <AdvertiserStatus
            advertiserUuid={dealStatus.advertiserStatusAdvertiserUuid}
            initialStatus={dealStatus.advertiserStatusValue}
            statusType={dealStatus.advertiserStatusType}
            tooltipId="table-tooltip"
          />
        ) : (
          <AdvertiserStatus
            advertiserDomain={row}
            initialStatus={''}
            statusType="deal"
            tooltipId="table-tooltip"
          />
        )}
      </div>
    )
  } else {
    if (noPrev) {
      return values[column]
    } else {
      return (
        <>
          <div>{values[column]}</div>
          <div className="text-smaller text-light m-t-1">
            {values.prev[column]}
          </div>
        </>
      )
    }
  }
}

const TrackerList = (props) => {
  try {
    const { trackerUuids } = props

    const trackers = useApiGet<{ trackerUuid: string; trackerName: string }[]>(
      '/advs/v1/camps/list',
      {
        trackerUuids: trackerUuids,
      }
    )

    if (isBlank(trackers)) return null
    const trackerNames = uniq(
      trackers.map((tracker) => tracker.trackerName)
    ).sort()
    if (!trackerNames.length) return null

    return (
      <SmallLoadable data={trackers}>
        <div className="row row-narrow row-nowrap">
          {trackerNames.map((trackerName) => (
            <div key={trackerName} className="badge text-smaller text-bold">
              {trackerName}
            </div>
          ))}
        </div>
      </SmallLoadable>
    )
  } catch (error) {
    console.error(error)
    return null
  }
}

const Graph = (props) => {
  const { isSingleDate } = props
  const { data, group, graphColumns, setGraphHeight } = useStatistics()

  return (
    <div className="relative">
      <div className="graph-container">
        {group === 'date' ? (
          <PerformanceAreaChart
            rows={data.rows}
            {...{
              graphColumns,
              setGraphHeight,
              isSingleDate,
            }}
          />
        ) : (
          <PerformanceBarChart
            rows={data.rows}
            {...{
              graphColumns,
              setGraphHeight,
            }}
          />
        )}
      </div>
    </div>
  )
}

const GraphSwitch = (props) => {
  const { title, value, selectedValues, setValues, color } = props

  const isGraphColumnShown = selectedValues.indexOf(value) !== -1

  return (
    <button
      className={varClass({
        'btn btn-sm m-r-15': true,
        'active': isGraphColumnShown,
      })}
      style={
        isGraphColumnShown
          ? {
              color: color,
              background: color + '1A',
            }
          : {}
      }
      onClick={() => {
        const newValues = [...selectedValues]
        if (isGraphColumnShown) {
          newValues.splice(newValues.indexOf(value), 1)
        } else {
          newValues.push(value)
        }
        setValues(newValues)
      }}
    >
      {title}
    </button>
  )
}

const DragTable = (props) => {
  const { shownRows } = props

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !currentOffset) {
    return null
  }

  const columns = [item?.value]

  return (
    <div
      className="insights-statistics-card drag-card"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
        pointerEvents: 'none',
        zIndex: 100,
      }}
    >
      <table
        className="table table-bordered table-sortable insights-statistics-table drag-table relative"
        style={{
          position: 'relative',
          width: 100,
          height: 100,
        }}
      >
        <thead className="text-nowrap">
          <tr>
            <th className="relative vertical-middle p-l-2 p-r-4 drag-highlight">
              <div className="row row-fill row-nowrap">
                <span className="cursor-grab text-blue m-r-10">
                  <SVG
                    src="/images/insights/icon-drag.svg"
                    className="handle"
                  />
                </span>
                <span className="column-label">
                  {tableLabels[item?.value]}
                  <SVG src="/images/insights/caret.svg" className="m-l-1" />
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-nowrap">
          {map(shownRows, (row, index) => (
            <DragTableItem key={row.grp || row.name || index} />
          ))}
          {isBlank(shownRows) && (
            <tr>
              <td colSpan={10} className="text-center text-light text-bolder">
                Nothing found
              </td>
            </tr>
          )}
          <tr className="blank-space">
            <td colSpan={10} />
          </tr>
        </tbody>
        <tfoot>
          <tr className="text-nowrap">
            {columns.map((column) => (
              <th key={column} className="text-right" />
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

const DragTableItem = () => {
  return (
    <tr>
      <td className="text-right" />
    </tr>
  )
}

const GroupByTime = (props) => {
  const { className, selected, setSelected, group } = props
  const criteria = useMemo(() => ['Date', 'Month', 'Year'], [])

  const [open, setOpen] = useState(false)

  const ref = useRef<HTMLDivElement>()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div className="group-by-time" ref={ref}>
      <button
        className={varClass({
          'time-select link action': true,
          [className]: !!className,
        })}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <SVG src="/images/insights/icon-calendar.svg" className="m-r-10" />
        Group by
        <SVG src="/images/chevron-down.svg" className="m-l-4 m-r--15" />
      </button>

      <div
        className={varClass({
          'table-columns-menu': true,
          'shown': open,
        })}
      >
        <ul>
          {map(criteria, (c, index) => {
            return (
              <li
                key={c}
                className={varClass({
                  'selected': group === c.toLowerCase(),
                })}
                onClick={() => {
                  setSelected(c)
                }}
              >
                {c}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const CompareDates = (props) => {
  const { className, enabled, setEnabled, datePickerState } = props

  const dates = useMemo(() => {
    const diff = dateDiff(
      datePickerState.range.startDate,
      datePickerState.range.endDate,
      'hours'
    )
    const fromDate = moment(datePickerState.range.startDate)
      .subtract(diff, 'hours')
      .format('DD / MM / YYYY')
    const toDate = moment(datePickerState.range.startDate)
      .subtract(1, 'day')
      .format('DD / MM / YYYY')
    if (fromDate === toDate) {
      return fromDate
    } else {
      return `${fromDate} - ${toDate}`
    }
  }, [datePickerState])

  return (
    <div className="compare-previous">
      <button
        className={varClass({
          'compare-toggle link toggle': true,
          'active': enabled,
          [className]: !!className,
        })}
        onClick={() => {
          setEnabled(!enabled)
        }}
        data-tip
        data-for="compare-tooltip"
      >
        <SVG src="/images/insights/icon-compare.svg" className="m-r-10" />
        Compare
      </button>
      <ReactTooltip
        id="compare-tooltip"
        type="light"
        effect="solid"
        place="bottom"
      >
        Compare with data from
        <br />
        {dates}
      </ReactTooltip>
    </div>
  )
}
